import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import Box from "../Box";
import GrayButton from "../Button/GrayButton";
import FlexContainer from "../FlexContainer";
import Icon from "../Icon";
import { IconVarient } from "../Icon/types";

export const ReadMoreCenter = ({ children }: any) => {
  const [readmore, setReadmore] = useState("close");
  const varients = {
    open: {
      height: "auto",
      opacity: 1,
      transition: {
        ease: "easeIn",
        duration: 0.5,
      },
    },
    close: {
      height: 0,
      opacity: 0,
      transition: {
        ease: "easeOut",
        duration: 0.5,
      },
    },
  };

  return (
    <>
      <AnimatePresence mode="wait">
        <motion.div
          initial={{
            height: 0,
            opacity: 0,
          }}
          variants={varients}
          animate={readmore}
          exit={{
            height: 0,
            opacity: 0,
            transition: {
              ease: "easeOut",
              duration: 0.5,
            },
          }}
        >
          {children}
        </motion.div>
      </AnimatePresence>
      <FlexContainer mt={"40px"}>
        <Box ml={"auto"} mr={"auto"}>
          <GrayButton
            size="md"
            label={readmore === "open" ? "Show Less" : "Show More"}
            suffixIcon={
              <Icon
                type={readmore === "close" ? "arrow-down" : "arrow-up"}
                varient={IconVarient.line}
              />
            }
            onClick={() => setReadmore(readmore === "open" ? "close" : "open")}
          />
        </Box>
      </FlexContainer>
    </>
  );
};

export default ReadMoreCenter;
